import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import _ from "lodash"

import ROUTES from "../components/Routes/routesContants"

import { LoaderSmall } from "../components/Loaders"

// Prime React
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"

import { gql, useQuery } from "@apollo/client"

// Context
import { UserContext } from "../context/userContext"

export default function AdvancedSearchResults({
  filteredResults = null,
  fetching,
}) {
  const [participants, setParticipants] = useState(null)

  const GET_PARTICIPANTS = gql`
    query DefaultParticipantsAdvSearch($staff_id: ID!) {
      participants(staff_id: $staff_id) {
        participant_id
        participant_alias
        status
        participant_name_first
        participant_name_last
        participant_phone_primary
        participant_email_account
      }
    }
  `

  const user = useContext(UserContext)

  const { loading } = useQuery(GET_PARTICIPANTS, {
    fetchPolicy: "network-only",
    variables: { staff_id: user.participant_id },
    onCompleted: ({ participants }) => setParticipants(participants),
  })

  if (loading || fetching) return <LoaderSmall />

  const results = filteredResults || participants

  if (!results) return null

  const handleResults = _.map(results, (participant) => {
    return {
      full_name: `${_.unescape(participant.participant_name_first)} ${_.unescape(participant.participant_name_last)}`,
      participant_id: participant.participant_alias,
      phone: participant.participant_phone_primary,
      email: participant.participant_email_account,
      link: (
        <Link
          to={ROUTES.PARTICIPANT_PROFILE}
          onClick={() => user.setParticipant(participant.participant_id)}
          className="btn-sm"
        >
          View
        </Link>
      ),
    }
  })

  return (
    <DataTable value={handleResults}>
      <Column field="full_name" sortable header="Name" />
      <Column field="participant_id" sortable header="ID" />
      <Column field="phone" sortable header="Phone" />
      <Column field="email" sortable header="Email" />
      <Column field="status" sortable header="Status" />
      <Column field="" sortable header="Program ID" />
      <Column field="link" header="View" />
    </DataTable>
  )
}
