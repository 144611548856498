// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Sidebar-Staff_participantMenu__Iugsj {\n  margin: 10px 0 30px;\n  padding: 15px 30px;\n  background: #ebebeb;\n  border-radius: 5px;\n}\n\n.Sidebar-Staff_tieredMenu__dMsyk {\n  margin-top: 10px;\n  background: #f4f4f4;\n  width: 100%;\n}\n\n.Sidebar-Staff_participantMenu__Iugsj ul {\n  margin: 0;\n}\n\nhr {\n  margin: 20px 0 30px;\n  height: 1px;\n  background: none;\n  border: none;\n  border-bottom: 1px solid var(--branded-light-gray);\n}\n\n.Sidebar-Staff_parentVertical__CvvhH{\n  display: inline-block;\n  width: 25%;\n  float: left;\n}\n\n.Sidebar-Staff_childVertical__VE-db{\n  width: 76%;\n  padding-top: 21px;\n}\n\n.Sidebar-Staff_clearFix__5BeJK{\n  overflow: hidden;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/styles/Sidebar-Staff.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,kDAAkD;AACpD;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".participantMenu {\n  margin: 10px 0 30px;\n  padding: 15px 30px;\n  background: #ebebeb;\n  border-radius: 5px;\n}\n\n.tieredMenu {\n  margin-top: 10px;\n  background: #f4f4f4;\n  width: 100%;\n}\n\n.participantMenu ul {\n  margin: 0;\n}\n\nhr {\n  margin: 20px 0 30px;\n  height: 1px;\n  background: none;\n  border: none;\n  border-bottom: 1px solid var(--branded-light-gray);\n}\n\n.parentVertical{\n  display: inline-block;\n  width: 25%;\n  float: left;\n}\n\n.childVertical{\n  width: 76%;\n  padding-top: 21px;\n}\n\n.clearFix{\n  overflow: hidden;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"participantMenu": "Sidebar-Staff_participantMenu__Iugsj",
	"tieredMenu": "Sidebar-Staff_tieredMenu__dMsyk",
	"parentVertical": "Sidebar-Staff_parentVertical__CvvhH",
	"childVertical": "Sidebar-Staff_childVertical__VE-db",
	"clearFix": "Sidebar-Staff_clearFix__5BeJK"
};
export default ___CSS_LOADER_EXPORT___;
