import { NavLink } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { GET_SAVED_BLOCKS, GET_USER } from "./queries"
import { UserContext } from "../../context/userContext"
import _ from "lodash"

export default function SupervisorName({ participant_id }) {
  const user = useContext(UserContext)
  const [nameStaff, setNameStaff] = useState(null)

  const { loading } = useQuery(GET_USER, {
    variables: {
      staff_id: participant_id,
      organization_id: user.organization_id,
    },
    onCompleted: (data) => {
      setNameStaff(data.user.staff_name_first + " " + data.user.staff_name_last)
    },
  })

  // useEffect(() => {}, [user.participant_id, loading])

  return <span>{!_.isNull(nameStaff) ? nameStaff : " N/A"}</span>
}
