import { DateTime } from "luxon"

//Assessment components
import AssessmentFormQuestionMatrix from "./AssessmentFormQuestionMatrix"
import { isMatrixQuestionType } from "../assessmentHelpers"
import elementTypes from "../ManagementForm/FormBuilder/Elements/elementTypes"
import {
  getAssessmentDateOnlyFromIso,
  getAssessmentDateOnlyISO,
  getAssessmentTimeOnlyFromIso,
  getAssessmentTimeOnlyISO,
} from "../ManagementForm/helpers/assessmentDateHelper"

//Formik
import FormikNumberInput from "../../../helpers/formikInputs/FormikNumberInput"
import FormikCheckboxInput from "../../../helpers/formikInputs/FormikCheckboxInput"
import FormikDropDown from "../../../helpers/formikInputs/FormikDropDown"
import FormikTextInput from "../../../helpers/formikInputs/FormikTextInput"
import FormikTextAreaInput from "../../../helpers/formikInputs/FormikTextAreaInput"
import FormikCalendar from "../../../helpers/formikInputs/FormikCalendar"
import FormikRadioButtonInput from "../../../helpers/formikInputs/FormikRadioButtonInput"
import FormikAddressInput from "../../../helpers/formikInputs/FormikAddressInput"
import { useField } from "formik"

//PrimeReact
import { Calendar } from "primereact/calendar"

//Styles
import formStyles from "../../../styles/Form-Inputs.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"

export default function AssessmentFormQuestionInput({
  question,
  answers,
  answerIndex,
  readOnly,
  displayAnswerValues,
}) {
  let valueFieldName = displayAnswerValues === false ? "NoValue" : "value"
  const singleValueName = `answers[${answerIndex}].${valueFieldName}`

  const [field, meta, helpers] = useField(singleValueName)

  if (answerIndex >= 0 || isMatrixQuestionType(question.type)) {
    switch (question.type.toLowerCase()) {
      case elementTypes.textElementId:
        return <FormikTextInput name={singleValueName} disabled={readOnly} />
      case elementTypes.paragraphElementId:
        return (
          <FormikTextAreaInput
            name={singleValueName}
            rows={question.height}
            disabled={readOnly}
          />
        )
      case elementTypes.numberElementId:
        return (
          <FormikNumberInput
            name={singleValueName}
            minFractionDigits={0}
            disabled={readOnly}
            minValue={question.minValue !== "" ? +question.minValue : undefined}
            maxValue={question.maxValue !== "" ? +question.maxValue : undefined}
          />
        )
      case elementTypes.dateElementId:
        let minDate = undefined
        let maxDate = undefined
        let dateValue = undefined
        let viewDate = undefined

        if (field.value) {
          dateValue = getAssessmentDateOnlyFromIso(field.value).toJSDate()
        }

        if (question.minValue) {
          minDate = getAssessmentDateOnlyFromIso(question.minValue)
        }

        if (question.maxValue) {
          maxDate = getAssessmentDateOnlyFromIso(question.maxValue)
          if (!dateValue) {
            viewDate = maxDate
          }
        }

        if (question.verifyAge && question.minAge) {
          const minAgeDate = DateTime.now().plus({ years: -question.minAge })

          if (!maxDate || minAgeDate > maxDate) {
            maxDate = minAgeDate

            if (!dateValue) {
              viewDate = maxDate
            }
          }
        }

        if (!question.allowPastDates) {
          minDate = DateTime.now()
        }

        if (!question.allowFutureDates) {
          maxDate = DateTime.now()
        }

        return (
          <Calendar
            value={dateValue}
            onChange={(e) => {
              if (minDate?.isValid && minDate.toJSDate() > e.value) {
                helpers.setValue(getAssessmentDateOnlyISO(minDate.toJSDate()))
              } else if (maxDate?.isValid && maxDate.toJSDate() < e.value) {
                helpers.setValue(getAssessmentDateOnlyISO(maxDate.toJSDate()))
              } else {
                helpers.setValue(getAssessmentDateOnlyISO(e.value))
              }
            }}
            disabled={readOnly}
            minDate={minDate?.toJSDate()}
            maxDate={maxDate?.toJSDate()}
            viewDate={viewDate?.toJSDate()}
            showIcon
            iconPos="left"
          />
        )
      case elementTypes.timeElementId:
        let minTime = undefined
        let maxTime = undefined
        let timeValue = undefined

        if (field.value) {
          timeValue = getAssessmentTimeOnlyFromIso(field.value)
        }

        if (question.minValue) {
          minTime = getAssessmentTimeOnlyFromIso(question.minValue)
        }

        if (question.maxValue) {
          maxTime = getAssessmentTimeOnlyFromIso(question.maxValue)
        }

        return (
          <>
            <Calendar
              value={timeValue?.isValid ? timeValue.toJSDate() : new Date()}
              onChange={(e) => {
                if (minTime?.isValid && minTime?.toJSDate() > e.value) {
                  helpers.setValue(getAssessmentTimeOnlyISO(minTime.toJSDate()))
                } else if (maxTime?.isValid && maxTime?.toJSDate() < e.value) {
                  helpers.setValue(getAssessmentTimeOnlyISO(maxTime.toJSDate()))
                } else {
                  helpers.setValue(getAssessmentTimeOnlyISO(e.value))
                }
              }}
              disabled={readOnly}
              style={{ width: "100%" }}
              showTime={true}
              hourFormat={"12"}
              maxDate={maxTime?.isValid ? maxTime.toJSDate() : undefined}
              minDate={minTime?.isValid ? minTime.toJSDate() : undefined}
              showIcon
              iconPos="left"
              icon={<span class="material-icons">schedule</span>}
              timeOnly={true}
            />
          </>
        )
      case elementTypes.checkboxElementId:
        return (
          <div className="radioGroup">
            {answers[answerIndex].multiValues.map((value, index) => {
              return (
                <FormikCheckboxInput
                  checkboxLabel={value.text}
                  name={`answers[${answerIndex}].multiValues[${index}].${valueFieldName}`}
                  disabled={readOnly}
                  key={`${value.id}_${value.index}`}
                />
              )
            })}
            {meta.touched && meta.error ? (
              <div className="errorLabel">
                <label
                  id={`${singleValueName}-error`}
                  className={formStyles.errFieldMsg}
                >
                  {meta.error}
                </label>
              </div>
            ) : null}
          </div>
        )
      case elementTypes.dropdownElementId:
        return (
          <FormikDropDown
            name={singleValueName}
            options={answers[answerIndex].multiValues}
            optionLabel={"text"}
            optionValue={"optionId"}
            disabled={readOnly}
          />
        )
      case elementTypes.radioElementId:
        return (
          <FormikRadioButtonInput
            name={singleValueName}
            options={answers[answerIndex].multiValues}
            optionLabel={"text"}
            optionValue={"optionId"}
            disabled={readOnly}
          />
        )
      case elementTypes.matrixElementId:
      case elementTypes.matrixCheckboxElementId:
        return (
          <AssessmentFormQuestionMatrix
            question={question}
            answers={answers}
            valueFieldName={valueFieldName}
            readOnly={readOnly}
          />
        )
      case elementTypes.descriptionElementId:
      case elementTypes.headingElementId:
        return null //Heading elements dont use an input
      case elementTypes.emailElementId:
        const elementProfile = elementTypes.findTypeProfile(
          elementTypes.emailElementId
        )

        return (
          <div>
            <div className={assessStyles.questionInputContainer}>
              <div className="icon formik">
                <span className={`material-icons`}>{elementProfile.icon}</span>
              </div>
              <FormikTextInput
                name={singleValueName}
                disabled={readOnly}
                placeholder="mail@example.com"
                keyFilter="email"
                hideErrorMessage={true}
              />
            </div>
            <label className={formStyles.errFieldMsg}>{meta.error}</label>
          </div>
        )
      case elementTypes.addressElementId:
        return (
          <FormikAddressInput
            name={singleValueName}
            disabled={readOnly}
            rows={question.height}
          />
        )
      case elementTypes.phoneElementId:
        return (
          <FormikTextInput
            name={singleValueName}
            disabled={readOnly}
            mask="(999) 999-9999"
            placeholder="(999) 999-9999"
          />
        )
      default:
        return <>Unknown question input type</>
    }
  } else {
    return <>Answer not found</>
  }
}
