// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NotificationsToast_gridContainer__gaQDU {\n    display: grid;\n    grid-template-areas:\n      'icon main close'\n      'icon main closeAndRead';\n    width:100%\n  }\n\n.NotificationsToast_gridItemIcon__Z908e { \n    grid-area: icon; \n    width: 30px;\n}\n\n.NotificationsToast_gridItemMain__lmPxD { \n  grid-area: main;\n  width: 300px; \n}\n\n.NotificationsToast_gridItemClose__nukH5 { \n  grid-area: close;\n  width: 30px;\n }\n\n.NotificationsToast_gridItemCloseAndRead__\\+qQUE { \n    grid-area: closeAndRead;\n    width: 30px;\n    padding-top: 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end\n    \n}\n\n\n  .NotificationsToast_actionButton__zOd7k {\n    min-height: 50px;\n    width: 140px;\n    align-items: center;\n    margin-top: 10px;\n  }", "",{"version":3,"sources":["webpack://./src/styles/NotificationsToast.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb;;8BAE0B;IAC1B;EACF;;AAEF;IACI,eAAe;IACf,WAAW;AACf;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,WAAW;CACZ;;AAED;IACI,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB;;AAEJ;;;EAGE;IACE,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;EAClB","sourcesContent":[".gridContainer {\n    display: grid;\n    grid-template-areas:\n      'icon main close'\n      'icon main closeAndRead';\n    width:100%\n  }\n\n.gridItemIcon { \n    grid-area: icon; \n    width: 30px;\n}\n\n.gridItemMain { \n  grid-area: main;\n  width: 300px; \n}\n\n.gridItemClose { \n  grid-area: close;\n  width: 30px;\n }\n\n.gridItemCloseAndRead { \n    grid-area: closeAndRead;\n    width: 30px;\n    padding-top: 15px;\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end\n    \n}\n\n\n  .actionButton {\n    min-height: 50px;\n    width: 140px;\n    align-items: center;\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": "NotificationsToast_gridContainer__gaQDU",
	"gridItemIcon": "NotificationsToast_gridItemIcon__Z908e",
	"gridItemMain": "NotificationsToast_gridItemMain__lmPxD",
	"gridItemClose": "NotificationsToast_gridItemClose__nukH5",
	"gridItemCloseAndRead": "NotificationsToast_gridItemCloseAndRead__+qQUE",
	"actionButton": "NotificationsToast_actionButton__zOd7k"
};
export default ___CSS_LOADER_EXPORT___;
