// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DailyPlannerNotifications_defaultGridContainer__3f8kF {\n    display: grid;\n    grid-template-areas:\n    'title'\n    'organization'\n    'description';\n    grid-template-columns: auto; \n    width:100%;\n    border-style: solid;\n    border-width: 1px;\n    border-color: lightgray;\n    padding: 1%;\n    margin-bottom: 10px;\n}\n\n.DailyPlannerNotifications_gridItemTitle__VAX\\+V{\n    grid-area: title;\n}\n\n.DailyPlannerNotifications_gridItemOrganization__Gkhy1{\n    grid-area: organization;\n}\n\n.DailyPlannerNotifications_gridItemDescription__vTz7X{\n    grid-area: description;\n}\n\n.DailyPlannerNotifications_notificationHeader__wrZ8Y {\n    font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/styles/dailyPlanner/DailyPlannerNotifications.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb;;;iBAGa;IACb,2BAA2B;IAC3B,UAAU;IACV,mBAAmB;IACnB,iBAAiB;IACjB,uBAAuB;IACvB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".defaultGridContainer {\n    display: grid;\n    grid-template-areas:\n    'title'\n    'organization'\n    'description';\n    grid-template-columns: auto; \n    width:100%;\n    border-style: solid;\n    border-width: 1px;\n    border-color: lightgray;\n    padding: 1%;\n    margin-bottom: 10px;\n}\n\n.gridItemTitle{\n    grid-area: title;\n}\n\n.gridItemOrganization{\n    grid-area: organization;\n}\n\n.gridItemDescription{\n    grid-area: description;\n}\n\n.notificationHeader {\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultGridContainer": "DailyPlannerNotifications_defaultGridContainer__3f8kF",
	"gridItemTitle": "DailyPlannerNotifications_gridItemTitle__VAX+V",
	"gridItemOrganization": "DailyPlannerNotifications_gridItemOrganization__Gkhy1",
	"gridItemDescription": "DailyPlannerNotifications_gridItemDescription__vTz7X",
	"notificationHeader": "DailyPlannerNotifications_notificationHeader__wrZ8Y"
};
export default ___CSS_LOADER_EXPORT___;
