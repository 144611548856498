import { useCallback, useContext, useEffect, useState } from "react"
import { LoaderSmall } from "../../../components/Loaders"

//GraphQL
import { useLazyQuery } from "@apollo/client"
import { GET_PARTICIPANT, GET_STAFF, GET_STAFF_NAMES_BY_IDS } from "../queries"

// Context
import { UserContext } from "../../../context/userContext"
import { LogError } from "../../../helpers/logger"

export default function ParticipantAssessmentFacilitator({
  assessment,
  facilitatorProfiles,
  onSaveFacilitatorProfile,
}) {
  const userCtx = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(true)
  const [facilitatorName, setFacilitatorName] = useState(undefined)

  const [getParticipant] = useLazyQuery(GET_PARTICIPANT, {
    fetchPolicy: "cache-and-network",
    variables: {
      participant_id: "",
      organization_id: "",
    },
  })

  const [getStaff] = useLazyQuery(GET_STAFF, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: "",
      organization_id: "",
    },
  })

  const [getSpecialStaff] = useLazyQuery(GET_STAFF_NAMES_BY_IDS, {
    fetchPolicy: "cache-and-network",
    variables: {
      staff_id: "",
      organization_id: "",
      staff_ids: []
    },
  })

  const getFacilitator = useCallback(async () => {
    try {
      const useOrg =
        assessment.organization_id ||
        assessment.organizationId ||
        userCtx.organization_id

      const isParticipant =
        assessment.staff_id === assessment.participantId ||
        !assessment.staff_id ||
        assessment.staff_id === "0"

      const useId = isParticipant
        ? assessment.participantId
        : assessment.staff_id
        let existingFacilitator = []

      if(!isParticipant && +useId < 0){
        //this is a special staff account, check if it has been logged against any organization
        existingFacilitator = facilitatorProfiles?.filter(
          (profile) =>
            profile.profile_id === useId
        )    
      }else{
        existingFacilitator = facilitatorProfiles?.filter(
          (profile) =>
            profile.profile_id === useId && profile.organization_id === useOrg
        )    
      }

      if (existingFacilitator?.length > 0) {
        setFacilitatorName(existingFacilitator[0].name)
        setIsLoading(false)
      } else if (isParticipant) {
        const participant = await getParticipant({
          variables: {
            participant_id: useId,
            organization_id: useOrg,
          },
        })

        const firstName =
          participant?.data?.participant?.participant_name_first || ""
        const lastName =
          participant?.data?.participant?.participant_name_last || ""
        const name = `${firstName} ${lastName}`

        onSaveFacilitatorProfile(useId, useOrg, name)

        setFacilitatorName(name)
        setIsLoading(false)
      } else if (+assessment.staff_id < 0){
        //Note: This step is required because the user graphQL query does not support special staff accounts. 
        //      If this changes, we should remove this special case.
        const staff = await getSpecialStaff({
          variables: {
            staff_id: "-12", //Assessments service user
            organization_id: assessment.organizationId,
            staff_ids: [useId]
          },
        })

        let name = "<Unknown>"

        if(staff?.data?.getStaffNamesByIds?.data){
          const parsedName = JSON.parse(staff?.data?.getStaffNamesByIds?.data)
          if(parsedName && parsedName.length > 0){
            name = parsedName[0].staff_full_name
          }
        }

 
        onSaveFacilitatorProfile(useId, useOrg, name)
        setFacilitatorName(name)
        setIsLoading(false)
      } else {
        const staff = await getStaff({
          variables: {
            staff_id: useId,
            organization_id:
              assessment.organization_id || userCtx.organization_id,
          },
        })

        const name = staff?.data?.user?.staff_full_name || ""

        onSaveFacilitatorProfile(useId, useOrg, name)
        setFacilitatorName(name)
        setIsLoading(false)
      }
    } catch (error) {
      LogError("Unable to retrieve facilitator name", error)
      userCtx.setNotificationError("Unable to retrieve facilitator name")
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment, userCtx.organization_id, facilitatorProfiles])

  useEffect(() => {
    getFacilitator()
  }, [getFacilitator])

  if (isLoading) {
    return <LoaderSmall />
  } else {
    return <>{facilitatorName}</>
  }
}
