import React, { useContext } from "react"

import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo"
import AdvancedSearch from "../../components/AdvancedSearch"

import styles from "../../styles/Home-Staff.module.css"
// import { Link } from "react-router-dom"

// Context
import { UserContext } from "../../context/userContext"

export default function Homepage() {
  const user = useContext(UserContext)
  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content">
        {/*<h1>My Participants</h1>
  <AdvancedSearch />*/}
      </div>
    </div>
  )
}
