// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Layout_header__Djwpo {\n  background-color: var(--branded-dark-gray);\n}\n\n.Layout_footer__yWHgf {\n  margin: 30px 0 15px 0;\n  font-size: 0.8rem;\n  text-align: center;\n}\n\n.Layout_top__-sosh {\n  padding: 5px 0;\n}\n\n.Layout_wrapper__fnEfK {\n  display: flex;\n  justify-content: space-between;\n}\n\n.Layout_secondary__k9PLu {\n  background-color: var(--branded-purple);\n}\n\n", "",{"version":3,"sources":["webpack://./src/styles/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;AAC5C;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".header {\n  background-color: var(--branded-dark-gray);\n}\n\n.footer {\n  margin: 30px 0 15px 0;\n  font-size: 0.8rem;\n  text-align: center;\n}\n\n.top {\n  padding: 5px 0;\n}\n\n.wrapper {\n  display: flex;\n  justify-content: space-between;\n}\n\n.secondary {\n  background-color: var(--branded-purple);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Layout_header__Djwpo",
	"footer": "Layout_footer__yWHgf",
	"top": "Layout_top__-sosh",
	"wrapper": "Layout_wrapper__fnEfK",
	"secondary": "Layout_secondary__k9PLu"
};
export default ___CSS_LOADER_EXPORT___;
