// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loader_wrapperSm__gxy5s {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.Loader_wrapperMed__GLJ6Q {\n  width: 100%;\n  min-height: 20vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.Loader_wrapperFull__NTol9 {\n  width: 100vw;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".wrapperSm {\n  width: 100%;\n  height: 30px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.wrapperMed {\n  width: 100%;\n  min-height: 20vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.wrapperFull {\n  width: 100vw;\n  min-height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperSm": "Loader_wrapperSm__gxy5s",
	"wrapperMed": "Loader_wrapperMed__GLJ6Q",
	"wrapperFull": "Loader_wrapperFull__NTol9"
};
export default ___CSS_LOADER_EXPORT___;
