import { Menubar } from "primereact/menubar"
import Notifications from "../../components/notifications/notifications"

export default function NavAuthenticatedUser() {
  const items = [{}]
  return (
  <div style={{display:"flex", flexDirection:"row"}}>
    <Menubar model={items} style={{width:"100%"}} />
    <Notifications />
  </div>)
}
