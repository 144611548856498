import { getURLOverrideValue } from "../components/DeveloperTools/ServiceUrls/serviceUrlHelpers"
import { LogError } from "../helpers/logger"

const getUrlValue = (key) => {
  try {
    const overrideValue = getURLOverrideValue(key)
    return overrideValue.overrideUrl || process.env[key]
  } catch (error) {
    LogError(`Unable to retrieve URL value for key: ${key}`, error)
    return undefined
  }
}

// Funciones que devuelven URLs específicas
function notificationsUrl() {
  return getUrlValue("REACT_APP_NOTIFICATIONS_API")
}

function tasksUrl() {
  return getUrlValue("REACT_APP_TASKS_API")
}

function referralsUrl() {
  return getUrlValue("REACT_APP_REFERRALS_API")
}

function gpraUrl() {
  return getUrlValue("REACT_APP_GPRA_API")
}

function telerecoveryApiUrl() {
  return getUrlValue("REACT_APP_TWILIO_MICROSERVICE_API")
}

function appointmentsApiUrl() {
  return getUrlValue("REACT_APP_APPOINTMENTS_API")
}

function documentSigningApiUrl() {
  return getUrlValue("REACT_APP_DOCUMENT_SIGNING_API")
}

function assessmentsApiUrl() {
  return getUrlValue("REACT_APP_ASSESSMENTS_API")
}

// Exportar las funciones
export {
  assessmentsApiUrl,
  notificationsUrl,
  tasksUrl,
  referralsUrl,
  gpraUrl,
  telerecoveryApiUrl,
  appointmentsApiUrl,
  documentSigningApiUrl,
}
