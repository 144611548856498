// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppointmentsCron_cronOption__vJvk-{\n    display: flex;\n    align-items: center;\n}\n\n.AppointmentsCron_cronOptionRadioButton__m57vh{\n    margin-right: 10px;\n}\n\n.AppointmentsCron_cronOptionPortion__Nccxj{\n    margin-right: 10px;\n}\n\n.AppointmentsCron_periodTypeDropDown__5E0Bl{\n    margin-bottom: 10px;\n}\n\n.AppointmentsCron_weeklyContainer__zigSU{\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.AppointmentsCron_weeklyOption__2iLqT {\n    width: 124px;\n}\n\n.AppointmentsCron_numberOptionDropDown__yfEQp{\n    width: 81px;\n}\n\n.AppointmentsCron_nDayOfWeekOptionDropDown__VVBVm{\n    width: 116px;\n}\n\n.AppointmentsCron_dayOfWeekOptionDropDown__gFhu3{\n    width: 146px;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/appointments/AppointmentsCron.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".cronOption{\n    display: flex;\n    align-items: center;\n}\n\n.cronOptionRadioButton{\n    margin-right: 10px;\n}\n\n.cronOptionPortion{\n    margin-right: 10px;\n}\n\n.periodTypeDropDown{\n    margin-bottom: 10px;\n}\n\n.weeklyContainer{\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.weeklyOption {\n    width: 124px;\n}\n\n.numberOptionDropDown{\n    width: 81px;\n}\n\n.nDayOfWeekOptionDropDown{\n    width: 116px;\n}\n\n.dayOfWeekOptionDropDown{\n    width: 146px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cronOption": "AppointmentsCron_cronOption__vJvk-",
	"cronOptionRadioButton": "AppointmentsCron_cronOptionRadioButton__m57vh",
	"cronOptionPortion": "AppointmentsCron_cronOptionPortion__Nccxj",
	"periodTypeDropDown": "AppointmentsCron_periodTypeDropDown__5E0Bl",
	"weeklyContainer": "AppointmentsCron_weeklyContainer__zigSU",
	"weeklyOption": "AppointmentsCron_weeklyOption__2iLqT",
	"numberOptionDropDown": "AppointmentsCron_numberOptionDropDown__yfEQp",
	"nDayOfWeekOptionDropDown": "AppointmentsCron_nDayOfWeekOptionDropDown__VVBVm",
	"dayOfWeekOptionDropDown": "AppointmentsCron_dayOfWeekOptionDropDown__gFhu3"
};
export default ___CSS_LOADER_EXPORT___;
