//Enterprise Admin 2
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { FirebaseContext } from "../../firebase"
import { UserContext } from "../../context/userContext"
import { Menubar } from "primereact/menubar"

// Hooks
import useLogs from "../../hooks/useLogs"

// Consts
import ROUTES from "../../components/Routes/routesContants"

export default function NavHeaderAuthenticatedUser() {
  const [logsEnable, writeLog] = useLogs()
  const user = useContext(UserContext)
  const firebase = useContext(FirebaseContext)
  const navigate = useNavigate()

  const handleSignOut = async () => {
    await firebase.signOut()
    if (logsEnable) {
      return await writeLog({
        type: "info",
        component: "Auth",
        message: `User logged out: ${user.participant_id}`,
        stack: "",
      })
    }
    return navigate("/")
  }

  const items = [
    {
      label: "Home",
      command: () => navigate(ROUTES.HOMEPAGE),
    },
    {
      label: "Logout",
      command: () => {
        sessionStorage.clear()
        navigate(ROUTES.HOMEPAGE)
        handleSignOut()
      },
    },
  ]

  return <Menubar model={items} />
}
