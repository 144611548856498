import React, { useContext, useState } from "react"
import _ from "lodash"
import { useQuery, useLazyQuery } from "@apollo/client"
import { Formik, Form } from "formik"
import * as Yup from "yup"

// Prime
import { Dropdown } from "primereact/dropdown"
import { MultiSelect } from "primereact/multiselect"
import { InputText as Input } from "primereact/inputtext"

// Context
import { UserContext } from "../context/userContext"

// Comps
import AdvancedSearchResults from "./AdvancedSearchResults"

// Styles
import styles from "../styles/Participant-Advaced-Search.module.css"

import { PROGRAM_ID_LIST, ADVANCED_SEARCH } from "../components/Queries/queries"

export default function AdvancedSearch() {
  const user = useContext(UserContext)
  const [fetching, setFetching] = useState(false)
  const [results, setResults] = useState(null)
  const [programIdList, setProgramIdList] = useState(null)
  const [toggle, setToggle] = useState(false)

  const { loading } = useQuery(PROGRAM_ID_LIST, {
    variables: {
      staff_id: user.participant_id,
      organization_id: user.organization_id
    },
    onCompleted: (values) => {
      if (!values) return
      return setProgramIdList(
        _.map(values.programs, (program) => {
          return {
            label: program.program_id_name,
            value: program.program_identity_id,
          }
        })
      )
    },
  })

  const [search, { loading: searching }] = useLazyQuery(ADVANCED_SEARCH, {
    onCompleted: (values) => {
      setResults(values.search)
      return setFetching(false)
    },
  })

  const ParticipantSchema = Yup.object().shape({
    participant_name_first: Yup.string(),
    participant_name_last: Yup.string(),
    participant_alias: Yup.string(),
    participant_phone_primary: Yup.string(),
    participant_email_account: Yup.string().email("Invalid email"),
  })

  const statusOptions = [
    { label: "Active Engaged", value: "Active Engaged" },
    { label: "Active Unengaged", value: "Active Unengaged" },
    { label: "Inactive Discharged", value: "Inactive Discharged" },
    { label: "Drop-in Referral", value: "Drop-in Referral" },
    { label: "Kiosk Member", value: "Kiosk MemberPRS" },
  ]

  const handleForm = (formik) => {
    if (toggle) return AdvancedForm(formik)
    return QuickForm(formik)
  }

  const QuickForm = (formik) => {
    return (
      <div className={styles.fields}>
        <div className={styles.field}>
          <label className={styles.label}>Participant Name:</label>
          <Input
            name="participant_name_first"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.participant_name_first}
          />
        </div>
      </div>
    )
  }

  const AdvancedForm = (formik) => {
    return (
      <div className={styles.fields}>
        <div className={styles.field}>
          <label className={styles.label}>Participant First Name:</label>
          <Input
            name="participant_name_first"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.participant_name_first}
          />
          {formik.touched.participant_name_first &&
            formik.errors.participant_name_first && (
              <div className={styles.errFieldMsg}>
                {formik.errors.participant_name_first}
              </div>
            )}
        </div>
        <div className={styles.field}>
          <label className={styles.label}>Participant Last Name:</label>
          <Input
            name="participant_name_last"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.participant_name_last}
          />
        </div>
        <div className={styles.field}>
          <label className={styles.label}>Participant ID:</label>
          <Input
            name="participant_alias"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.participant_alias}
          />
        </div>
        <div className={styles.field}>
          <label className={styles.label}>Phone:</label>
          <Input
            name="participant_phone_primary"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.participant_phone_primary}
          />
        </div>
        <div className={styles.field}>
          <label className={styles.label}>Email:</label>
          <Input
            name="participant_email_account"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.participant_email_account}
          />
          {formik.touched.participant_email_account &&
            formik.errors.participant_email_account && (
              <div className={styles.errFieldMsg}>
                {formik.errors.participant_email_account}
              </div>
            )}
        </div>
        <div className={styles.field}>
          <label className={styles.label}>Status:</label>
          <MultiSelect
            placeholder="Please select"
            id="participant_membership_status"
            name="participant_membership_status"
            value={formik.values.participant_membership_status}
            onChange={formik.handleChange}
            options={statusOptions}
          />
        </div>
        <div className={styles.field}>
          <label className={styles.label}>Programs:</label>
          <MultiSelect
            placeholder="Please select"
            id="program_ids"
            name="program_ids"
            value={formik.values.program_ids}
            onChange={formik.handleChange}
            options={programIdList}
          />
        </div>
      </div>
    )
  }

  if (loading) return null

  return (
    <div className={styles.wrapper}>
      <Formik
        initialValues={{
          participant_name_first: "",
          participant_name_last: "",
          participant_alias: "",
          participant_phone_primary: "",
          participant_email_account: "",
          participant_membership_status: [],
          program_ids: [],
        }}
        validationSchema={ParticipantSchema}
        onSubmit={async (values) => {
          setFetching(true)
          search({
            variables: {
              participant_name_first: values.participant_name_first,
              participant_name_last: values.participant_name_last,
              participant_alias: values.participant_alias,
              participant_phone_primary: values.participant_phone_primary,
              participant_email_account: values.participant_email_account,
              participant_membership_status: values.participant_membership_status,
              program_ids: values.program_ids,
            },
          })
        }}
      >
        {(formik) => {
          return (
            <Form>
              {handleForm(formik)}
              <div className={styles.buttons}>
                <button className="btn-sm" type="submit" disabled={searching}>
                  Select
                </button>
                <button
                  className="btn-sm"
                  type="button"
                  disabled={searching}
                  onClick={() => {
                    formik.resetForm()
                    setResults(null)
                  }}
                >
                  Clear
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
      <button onClick={() => setToggle(!toggle)} className={styles.toggle}>
        {toggle ? "Quick Search" : "Advanced Search"}
      </button>
      <AdvancedSearchResults filteredResults={results} fetching={fetching} />
    </div>
  )
}
