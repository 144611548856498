// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nav-Blocks_wrapper__Tsksq {\n  display: flex;\n  gap: 5px\n}\n\n.Nav-Blocks_next__r5p-v {\n  display: block;\n  cursor: pointer;\n  border: 0;\n  color: black;\n  text-decoration: underline;\n  background: none;\n  border-radius: 5px;\n  padding: 0;\n}\n\nbutton.Nav-Blocks_next__r5p-v:disabled {\n  background: none;\n  padding: 0;\n  border: 0;\n}\n.Nav-Blocks_save__aV3ZG {\n  display: block;\n  cursor: pointer;\n  border: 0;\n  color: white;\n  background: var(--branded-validation-green);\n  border-radius: 5px;\n  padding: 5px 15px;\n}\n\n.Nav-Blocks_edit__uTfEf {\n  display: block;\n  cursor: pointer;\n  border: 0;\n  color: var(--branded-darker-gray);\n  background: var(--branded-yellow);\n  border-radius: 5px;\n  padding: 5px 15px;\n}", "",{"version":3,"sources":["webpack://./src/styles/Nav-Blocks.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb;AACF;;AAEA;EACE,cAAc;EACd,eAAe;EACf,SAAS;EACT,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;AACA;EACE,cAAc;EACd,eAAe;EACf,SAAS;EACT,YAAY;EACZ,2CAA2C;EAC3C,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,SAAS;EACT,iCAAiC;EACjC,iCAAiC;EACjC,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  display: flex;\n  gap: 5px\n}\n\n.next {\n  display: block;\n  cursor: pointer;\n  border: 0;\n  color: black;\n  text-decoration: underline;\n  background: none;\n  border-radius: 5px;\n  padding: 0;\n}\n\nbutton.next:disabled {\n  background: none;\n  padding: 0;\n  border: 0;\n}\n.save {\n  display: block;\n  cursor: pointer;\n  border: 0;\n  color: white;\n  background: var(--branded-validation-green);\n  border-radius: 5px;\n  padding: 5px 15px;\n}\n\n.edit {\n  display: block;\n  cursor: pointer;\n  border: 0;\n  color: var(--branded-darker-gray);\n  background: var(--branded-yellow);\n  border-radius: 5px;\n  padding: 5px 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "Nav-Blocks_wrapper__Tsksq",
	"next": "Nav-Blocks_next__r5p-v",
	"save": "Nav-Blocks_save__aV3ZG",
	"edit": "Nav-Blocks_edit__uTfEf"
};
export default ___CSS_LOADER_EXPORT___;
