// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Appointments_tooltipIcon__0ouc3{\n    color: var(--branded-purple);\n    margin-top: 10px;\n}\n\n.Appointments_tooltip__3SRfN{\n    color: var(--branded-purple);\n}\n\n.Appointments_tooltip__3SRfN .p-tooltip-arrow{\n    color: var(--branded-purple);\n}\n\n.Appointments_tooltip__3SRfN .p-tooltip-text{\n    background-color: white;\n    border-color: var(--branded-purple);\n    border: 1px solid;\n    color: var(--branded-purple);\n}\n\n.Appointments_tooltipIcon__0ouc3 .p-tooltip .p-component .p-tooltip-left .p-tooltip-active{\n    background-color: white;\n    color: var(--branded-purple);\n}", "",{"version":3,"sources":["webpack://./src/styles/appointments/Appointments.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;IACvB,mCAAmC;IACnC,iBAAiB;IACjB,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;IACvB,4BAA4B;AAChC","sourcesContent":[".tooltipIcon{\n    color: var(--branded-purple);\n    margin-top: 10px;\n}\n\n.tooltip{\n    color: var(--branded-purple);\n}\n\n.tooltip :global(.p-tooltip-arrow){\n    color: var(--branded-purple);\n}\n\n.tooltip :global(.p-tooltip-text){\n    background-color: white;\n    border-color: var(--branded-purple);\n    border: 1px solid;\n    color: var(--branded-purple);\n}\n\n.tooltipIcon :global(.p-tooltip .p-component .p-tooltip-left .p-tooltip-active){\n    background-color: white;\n    color: var(--branded-purple);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipIcon": "Appointments_tooltipIcon__0ouc3",
	"tooltip": "Appointments_tooltip__3SRfN"
};
export default ___CSS_LOADER_EXPORT___;
