const GLOBALS_ENVIRONMENT = {
  environmentName: process.env.ENVIRONMENT,
  isLocal: process.env.ENVIRONMENT === "localhost",
  isDevelopment: process.env.ENVIRONMENT === "development",
  isStaging: process.env.ENVIRONMENT === "staging",
  isProduction: process.env.ENVIRONMENT === "production",
  isQAProduction: process.env.ENVIRONMENT === "qaproduction",
}

module.exports = GLOBALS_ENVIRONMENT
