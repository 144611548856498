// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form-NestedMB_nestedMultiBlock__LVRey {\n  border-radius: 5px;\n  border: 1px solid var(--branded-gray);\n  border-left: 8px solid var(--branded-gray);\n  padding: 8px 16px;\n  margin: 20px 0;\n}\n\n.Form-NestedMB_nestedMultiBlock__LVRey h4 {\n  margin: 0 0 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/Form-NestedMB.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qCAAqC;EACrC,0CAA0C;EAC1C,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".nestedMultiBlock {\n  border-radius: 5px;\n  border: 1px solid var(--branded-gray);\n  border-left: 8px solid var(--branded-gray);\n  padding: 8px 16px;\n  margin: 20px 0;\n}\n\n.nestedMultiBlock h4 {\n  margin: 0 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nestedMultiBlock": "Form-NestedMB_nestedMultiBlock__LVRey"
};
export default ___CSS_LOADER_EXPORT___;
