// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form-Btn-Submit_submit__agSCm {\n  display: block;\n  text-align: center;\n  cursor: pointer;\n  color: white;\n  font-size: 1rem;\n  padding: 20px;\n  border: none;\n  border-radius: 4px;\n  background-color: var(--branded-validation-green);\n  width: 100%;\n  margin-top: 30px;\n}", "",{"version":3,"sources":["webpack://./src/styles/Form-Btn-Submit.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,YAAY;EACZ,eAAe;EACf,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iDAAiD;EACjD,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".submit {\n  display: block;\n  text-align: center;\n  cursor: pointer;\n  color: white;\n  font-size: 1rem;\n  padding: 20px;\n  border: none;\n  border-radius: 4px;\n  background-color: var(--branded-validation-green);\n  width: 100%;\n  margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit": "Form-Btn-Submit_submit__agSCm"
};
export default ___CSS_LOADER_EXPORT___;
